import React, { ReactElement } from 'react'
import MainLayout from '@layouts/main-layout'
import HomeShop from '@components/home/home-shop'
import HomeDeals from '@components/home/home-deals'
import FeaturedProducts from '@components/home/product-feature/featured-products'
import HomeFooter from '@components/home/home-footer'
import { InferGetServerSidePropsType } from 'next'
import HeaderSlideBanner from '@components/home/header-slide-banner'
import { listCategories } from '@lib/util/category-handler'
import { getMainBanners } from '@lib/util/banner-handler'
import { listProductFeatures } from '@lib/util/product-feature-handler'
import Reels from '@components/home/home-reels'

export const getServerSideProps = async () => {
  const banners = await getMainBanners()
  const { product_categories: productCategories } = await listCategories()
  const { product_features: featuredProducts } = await listProductFeatures()

  return {
    props: {
      banners,
      productCategories,
      featuredProducts
    }
  }
}

const HomePage = ({
  banners,
  productCategories,
  featuredProducts
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <>
      <HeaderSlideBanner banners={banners} />
      <HomeShop productCategories={productCategories} />
      <HomeDeals />
      <FeaturedProducts products={featuredProducts} />
      <Reels />
      <HomeFooter />
    </>
  )
}

HomePage.getLayout = (page: ReactElement) => {
  return <MainLayout>{page}</MainLayout>
}

export default HomePage
