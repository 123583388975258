import { MainBanner } from '../../types/image'

const checkDateExpired = (start: Date, end: Date) => {
  return end.getTime() > start.getTime()
}

export const filterSlideBanner = (banners: MainBanner[]) => {
  return banners.filter((banner) => {
    if (banner.start_date === null && banner.end_date === null) {
      return banner
    } else if (banner.start_date != null) {
      if (checkDateExpired(new Date(banner.start_date), new Date())) {
        if (banner.end_date != null) {
          if (!checkDateExpired(new Date(banner.end_date), new Date())) {
            return banner
          }
        } else {
          return banner
        }
      }
    }
  })
}
